// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --first-family: "Cormorant", Arial, sans-serif;
  --second-family: "Inter", Arial, sans-serif;

  --content-width: 1350px;
  --container-offset: 25px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #eee;
  --dark-color: #000;
  --first-color: #cf294b;
}

@media (max-width: 1024px) {
  :root {
    --second-family: Arial, sans-serif;
  }
}