/* stylelint-disable */
@mixin desktop {
  @media (min-width: (1201px)) {
    @content;
  }
}


@mixin minidesk {
  @media (max-width: (1200px)) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: (1024px)) {
    @content;
  }
}

@mixin minitablet {
  @media (max-width: (768px)) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: (532px)) {
    @content;
  }
}