.comments {
  position: relative;
  background-color: var(--dark-color);
  padding-top: 80px;
  padding-bottom: 100px;

  @include mobile {
    padding-bottom: 60px;
  }

  &__swiper-slide {
    display: flex;
    justify-content: center;
    background-color: #000;
    padding-bottom: 75px;
  }

  &__screen {
    display: block;
    width: 100%;
    height: 480px;

    @include hover {
      filter: opacity(90%);
    }

    @include minidesk {
      height: initial;
    }
  }

  &__title {
    font-size: 44px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--light-color);
    margin-bottom: 70px;

    @include tablet {
      font-size: 36px;
    }

    @include mobile {
      font-size: 28px;
    }
  }

  &__swiper {
    width: 100%;
    margin-bottom: 20px;

    @include mobile {
      margin-bottom: 30px;

    }
  }


  &__text {
    font-family: var(--second-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    padding: 15px;
    padding-left: 160px;
    padding-right: 150px;
  }

  &__btn-next,
  &__btn-prev {
    position: absolute;
    z-index: 5;
    bottom: 16%;
    width: 44px;
    height: 44px;
    padding-top: 5px;
    border-radius: 50%;
    background-color: transparent;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

    @include mobile {
      width: 30px;
      height: 30px;
    }

    & svg {
      fill: #fff;
    }

    @include hover {
      background-color: var(--first-color);
    }

    &:active {
      background-color: #555;
    }

    &:disabled {
      cursor: default;
      background-color: transparent;
    }

    &:disabled svg {
      fill: rgba(255, 255, 255, 0.43)
    }

  }

  &__btn-prev {
    left: 40px;

    @include mobile {
      left: 25px;
    }
  }

  &__btn-next {
    right: 40px;

    @include mobile {
      right: 25px;
    }
  }

  &__btn-next svg {
    width: 23px;
    height: 14px;

    @include mobile {
      width: 12px;
      height: 11px;
    }
  }

  &__btn-prev svg {
    width: 23px;
    height: 14px;

    @include mobile {
      width: 12px;
      height: 11px;
    }
  }

  &__more {
    display: flex;
    justify-content: center;
  }

  &__more-link {
    display: inline-block;
    font-family: var(--second-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;
    color: var(--light-color);
    text-align: center;


    @include tablet {
      font-size: 16px;
    }

    @include hover {
      color: var(--first-color);
    }

    &:active {
      color: var(--light-color);
    }

  }

}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: var(--light-color);
  opacity: 0.5;
}

.swiper-pagination-bullet:not(:last-child) {
  margin-right: 8px !important;
}

.swiper-pagination-bullet-active {
  background-color: var(--first-color);
  opacity: 1;
}