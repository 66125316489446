:root {
  --first-family: "Cormorant", Arial, sans-serif;
  --second-family: "Inter", Arial, sans-serif;
  --content-width: 1350px;
  --container-offset: 25px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light-color: #eee;
  --dark-color: #000;
  --first-color: #cf294b;
}

@media (max-width: 1024px) {
  :root {
    --second-family: Arial, sans-serif;
  }
}
/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable value-keyword-case */
@font-face {
  font-family: "Cormorant";
  src: url("../fonts/../fonts/Cormorant-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Cormorant";
  src: url("../fonts/../fonts/Cormorant-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Cormorant";
  src: url("../fonts/../fonts/Cormorant-Bold.woff2") format("woff2");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/Inter-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/Inter-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/Inter-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/Inter-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a:focus {
  outline: 1px solid var(--light-color);
  outline-offset: 2px;
}

button:focus {
  outline: 1px solid var(--light-color);
  outline-offset: 2px;
}

.page {
  height: 100%;
  font-family: var(--first-family);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 315px;
  min-height: 100%;
  font-size: 16px;
  color: var(--light-color);
  background-color: var(--light-color);
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
  transition: 0.3s color ease-in-out;
}

input {
  padding-left: 8px;
  padding-right: 8px;
  outline: none;
}
input:focus {
  outline: 2px solid var(--first-color);
}

textarea {
  padding: 8px;
  outline: none;
}
textarea:focus {
  outline: 2px solid var(--first-color);
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.btn {
  border: 1px solid var(--first-color);
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}
@media (any-hover: hover) {
  .btn:hover {
    background-color: var(--light-color);
    color: var(--first-color);
  }
}

.btn:active {
  background-color: var(--first-color);
  color: var(--light-color);
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}
@media (max-width: 1024px) {
  .container {
    padding: 0 20px;
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
}

.custom-checkbox__input {
  position: absolute;
  opacity: 0;
}
.custom-checkbox__text {
  position: relative;
  display: inline-block;
  padding-left: 27px;
  font-size: 19px;
  line-height: 150%;
  margin-bottom: 10px;
}
.custom-checkbox__text::before {
  content: "";
  position: absolute;
  left: 2px;
  top: 4px;
  display: inline-block;
  border: 1px solid #222;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  transition: background-color 0.3s ease-in-out;
}
.custom-checkbox__text::after {
  content: "";
  position: absolute;
  left: 2px;
  top: 4px;
  width: 19px;
  height: 19px;
  background-image: url("../img/check.svg");
  background-position: center;
  background-size: 10px 8px;
  background-repeat: no-repeat;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.custom-checkbox__input:checked + .custom-checkbox__text::before {
  background-color: var(--first-color);
}
.custom-checkbox__input:checked + .custom-checkbox__text::after {
  opacity: 1;
}
.custom-checkbox__input:focus + .custom-checkbox__text {
  outline: 2px solid var(--first-color);
}

.custom-radio__input {
  position: absolute;
  opacity: 0;
}
.custom-radio__text {
  position: relative;
  display: inline-block;
  padding-left: 27px;
  font-size: 19px;
  line-height: 160%;
  color: #999;
}
.custom-radio__text::before {
  content: "";
  position: absolute;
  left: -1px;
  top: 4px;
  display: inline-block;
  border: 1px solid #b3b7bc;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  transition: border-color 0.3s ease-in-out;
}
.custom-radio__text::after {
  content: "";
  position: absolute;
  left: -1px;
  top: 4px;
  width: 16px;
  height: 16px;
  background-image: url("../img/check-circle.svg");
  background-position: center;
  background-size: 8px 8px;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.custom-radio__input:checked + .custom-radio__text::before {
  border-color: var(--first-color);
}
.custom-radio__input:checked + .custom-radio__text::after {
  opacity: 1;
}
.custom-radio__input:focus + .custom-radio__text {
  outline: 3px solid var(--first-color);
}

.graph-modal__container {
  width: 30%;
  min-width: 620px;
  color: var(--dark-color);
  background-color: var(--light-color);
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (max-width: 768px) {
  .graph-modal__container {
    min-width: 490px;
    width: 80%;
  }
}
@media (max-width: 532px) {
  .graph-modal__container {
    min-width: 307px;
    width: 95%;
  }
}
.graph-modal__title {
  font-size: 40px;
}
@media (max-width: 768px) {
  .graph-modal__title {
    font-size: 30px;
  }
}
@media (max-width: 532px) {
  .graph-modal__title {
    font-size: 26px;
  }
}
.graph-modal__subtitle {
  display: block;
  font-size: 30px;
  margin-bottom: 28px;
}
@media (max-width: 768px) {
  .graph-modal__subtitle {
    font-size: 26px;
  }
}
@media (max-width: 532px) {
  .graph-modal__subtitle {
    font-size: 24px;
  }
}
.graph-modal__form-container {
  display: flex;
  flex-direction: column;
  font-family: var(--second-family);
}
.graph-modal__form-label {
  font-size: 19px;
  margin-bottom: 35px;
}
.graph-modal__form-input {
  display: block;
  width: 100%;
  margin-top: 5px;
  height: 38px;
}
.graph-modal__form-text {
  display: block;
  width: 100%;
  margin-top: 8px;
  height: 80px;
}
.graph-modal__form-btn {
  display: block;
  background-color: var(--first-color);
  color: var(--light-color);
  font-size: 18px;
  height: 60px;
  margin-bottom: 20px;
}
.graph-modal__form-btn:focus {
  background-color: var(--light-color);
  color: var(--first-color);
  border-width: 2px;
}
.graph-modal__error {
  position: absolute;
  color: var(--first-color);
  font-size: 16px;
  bottom: 325px;
  opacity: 0;
}
.graph-modal__error--active {
  opacity: 1;
}
.graph-modal__close:focus {
  outline: 2px solid var(--first-color);
  outline-offset: 2px;
}

.just-validate-error-label {
  position: absolute;
  margin-top: 5px;
  font-size: 16px;
}

.graph-modal__form-btn + .just-validate-error-label {
  bottom: 114px;
}

.send {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
  animation: anim 350ms ease-in-out;
}
.send__content {
  position: fixed;
  z-index: 200;
  width: 300px;
  height: 200px;
  background-color: white;
  top: calc(50% - 200px);
  left: calc(50% - 150px);
  border: 1px solid gray;
  padding-top: 30px;
  padding-bottom: 30px;
}
.send__text {
  font-size: 20px;
  font-family: var(--second-family);
  color: var(--dark-color);
  text-align: center;
  margin-bottom: 10px;
}
.send__text:nth-child(2) {
  margin-bottom: 38px;
}
.send__btn {
  display: block;
  font-size: 20px;
  font-family: var(--second-family);
  width: 150px;
  height: 36px;
  background-color: var(--first-color);
  color: white;
  margin: 0 auto;
}
.send__btn:focus {
  outline: 2px solid var(--first-color);
  outline-offset: 4px;
}

.send--active {
  display: block;
}

.send--active::backdrop {
  background-color: red;
}

@keyframes anim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.header {
  position: fixed;
  width: 100%;
  z-index: 7;
  background-color: var(--dark-color);
  padding-bottom: 10px;
}
@media (max-width: 1024px) {
  .header {
    position: absolute;
  }
}
.header__container {
  position: relative;
  padding-top: 10px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto 1fr;
  align-items: center;
  column-gap: 30px;
  row-gap: 0;
  justify-items: stretch;
}
@media (max-width: 768px) {
  .header__container {
    row-gap: 15px;
  }
}
@media (max-width: 532px) {
  .header__container {
    justify-content: center;
  }
}
.header__person {
  grid-column: 1/3 span;
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  min-width: 214px;
}
@media (max-width: 1024px) {
  .header__person {
    grid-column: 1/3 span;
    font-size: 22px;
  }
}
@media (max-width: 768px) {
  .header__person {
    grid-column: 1/3 span;
  }
}
@media (max-width: 532px) {
  .header__person {
    grid-column: 1/12 span;
    grid-row: 1;
    text-align: center;
  }
}
.header__logo {
  grid-column: 7/2 span;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 75px;
  font-size: 24px;
  font-weight: 400;
  color: var(--light-color);
}
@media (max-width: 1200px) {
  .header__logo {
    font-size: 22px;
    width: 210px;
  }
}
@media (max-width: 1024px) {
  .header__logo {
    font-size: 20px;
    width: 200px;
    height: 60px;
    grid-column: 5/5 span;
  }
}
@media (max-width: 768px) {
  .header__logo {
    grid-column: 1/3 span;
  }
}
@media (max-width: 532px) {
  .header__logo {
    grid-column: 1/12 span;
    grid-row: 3;
    margin: 0 auto;
    margin-bottom: 14px;
  }
}
.header__logo img {
  transition: opacity 0.3s ease-in-out;
}
@media (any-hover: hover) {
  .header__logo img:hover {
    opacity: 0;
  }
}
.header__logo:active img {
  opacity: 1;
}
.header__img {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 1;
}
.header__logo-text {
  display: block;
  position: absolute;
}
.header__button {
  display: block;
  min-width: 280px;
  grid-column: 10/3 span;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: var(--first-color);
  color: var(--light-color);
  padding: 20px 10px;
}
@media (max-width: 1024px) {
  .header__button {
    min-width: 218px;
    font-size: 12px;
    padding: 10px 7px;
    grid-row: 2;
    position: relative;
    bottom: 33px;
  }
}
@media (max-width: 768px) {
  .header__button {
    grid-column: 4/9 span;
    justify-self: end;
    bottom: 0;
    width: 228px;
  }
}
@media (max-width: 532px) {
  .header__button {
    grid-column: 1/12 span;
    grid-row: 4;
    margin: 0 auto;
  }
}
.header__phone {
  margin-top: 2px;
  grid-column: 4/3 span;
  min-width: 130px;
  font-family: var(--second-family);
  font-weight: 400;
  color: inherit;
}
@media (max-width: 1024px) {
  .header__phone {
    margin-top: 0;
    position: relative;
    bottom: 21px;
    grid-column: 11/2 span;
    grid-row: 1;
    text-align: end;
  }
}
@media (max-width: 768px) {
  .header__phone {
    min-width: 0;
    grid-column: 6/7 span;
    bottom: 0;
  }
}
@media (max-width: 532px) {
  .header__phone {
    grid-column: 1/12 span;
    grid-row: 2;
    text-align: center;
  }
}

.hero {
  position: relative;
  background-color: var(--dark-color);
  padding-top: 130px;
  padding-bottom: 86px;
}
@media (max-width: 768px) {
  .hero {
    padding-top: 180px;
  }
}
@media (max-width: 532px) {
  .hero {
    padding-top: 280px;
    text-align: center;
  }
}
.hero__bg {
  position: absolute;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("../img/gruppa.jpg");
  opacity: 0.25;
}
.hero__container {
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .hero__container {
    display: none;
  }
}
.hero__logo {
  display: flex;
  position: absolute;
  left: 0;
  width: 10%;
  height: 10%;
  z-index: 10;
  justify-content: center;
  align-items: center;
  opacity: 0;
}
.hero__logo--active {
  animation: ani 8s forwards 0.8s;
}
.hero__logo img {
  width: 100%;
}
@media (max-width: 1024px) {
  .hero__logo {
    display: none;
  }
}
.hero__photo {
  width: 75%;
}
@media (max-width: 768px) {
  .hero__photo {
    margin-bottom: 40px;
    width: 80%;
  }
}
.hero__title {
  width: 80%;
  font-weight: 700;
  font-size: 80px;
  line-height: 120%;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--light-color);
  margin-bottom: 40px;
}
@media (max-width: 1200px) {
  .hero__title {
    font-size: 70px;
  }
}
@media (max-width: 1024px) {
  .hero__title {
    font-size: 36px;
  }
}
@media (max-width: 768px) {
  .hero__title {
    margin-bottom: 55px;
  }
}
@media (max-width: 532px) {
  .hero__title {
    font-size: 28px;
  }
}
.hero__title span {
  display: block;
  font-family: var(--second-family);
  font-size: 16px;
  line-height: 120%;
  font-weight: 300;
  margin-bottom: 30px;
}
@media (max-width: 1024px) {
  .hero__title span {
    font-size: 15px;
  }
}
.hero__text {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  width: 70%;
  margin-bottom: 50px;
}
@media (max-width: 1024px) {
  .hero__text {
    width: 80%;
  }
}
.hero__sign {
  display: flex;
  gap: 3px;
  height: 62px;
  width: 70%;
}
@media (max-width: 1024px) {
  .hero__sign {
    height: 45px;
  }
}
.hero__sign-icon {
  width: 62px;
  height: 100%;
  background-color: #cf294b;
  background-image: url("../img/icon1.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 40px;
}
@media (max-width: 532px) {
  .hero__sign-icon {
    display: none;
  }
}
.hero__sign-btn {
  position: relative;
  z-index: 7;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: #cf294b;
  color: var(--light-color);
  padding: 10px 20px;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .hero__sign-btn {
    font-size: 16px;
    padding: 8px 10px;
  }
}
.hero__container--mobile {
  display: none;
}
@media (max-width: 768px) {
  .hero__container--mobile {
    display: flex;
    flex-direction: column;
  }
}

@keyframes ani {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
    width: 100%;
    height: 100%;
  }
  50% {
    opacity: 1;
    width: 100%;
    height: 100%;
  }
  100% {
    opacity: 0;
    width: 10%;
    height: 10%;
  }
}
.about-me {
  background-color: var(--dark-color);
  padding-top: 125px;
  padding-bottom: 100px;
}
@media (max-width: 1024px) {
  .about-me {
    padding-top: 90px;
  }
}
@media (max-width: 768px) {
  .about-me {
    padding-top: 10px;
  }
}
.about-me__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .about-me__container {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
}
.about-me__title {
  font-size: 46px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 30px;
}
@media (max-width: 1024px) {
  .about-me__title {
    font-size: 36px;
  }
}
@media (max-width: 532px) {
  .about-me__title {
    font-size: 28px;
  }
}
.about-me__photo {
  width: 49%;
}
@media (max-width: 1024px) {
  .about-me__photo {
    width: 100%;
  }
}
.about-me__info {
  width: 47%;
}
@media (max-width: 1024px) {
  .about-me__info {
    width: 100%;
  }
}
.about-me__text {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 150%;
  font-family: var(--second-family);
  font-weight: 400;
}
.about-me__text:nth-last-child(3) {
  margin-bottom: 40px;
}
@media (max-width: 1024px) {
  .about-me__text {
    font-size: 15px;
  }
}
.about-me__detail {
  display: inline-block;
  position: relative;
  color: var(--light-color);
  font-size: 18px;
  font-family: var(--second-family);
  font-weight: 400;
}
@media (max-width: 1024px) {
  .about-me__detail {
    font-size: 16px;
    margin-bottom: 50px;
  }
}
.about-me__detail:not(:last-child) {
  margin-right: 30px;
}
.about-me__detail::after {
  position: absolute;
  right: -12px;
  top: 6px;
  content: "";
  width: 10px;
  height: 10px;
  border-top: 2px solid white;
  border-right: 2px solid white;
  transform: rotate(45deg);
  transition: color 0.3s ease-in-out, border 0.3s ease-in-out;
}
@media (any-hover: hover) {
  .about-me__detail:hover {
    color: #db833c;
  }
}
@media (any-hover: hover) {
  .about-me__detail:hover::after {
    color: #db833c;
    border-top: 2px solid #db833c;
    border-right: 2px solid #db833c;
  }
}
.about-me__detail:active {
  color: #cf294b;
}
.about-me__detail:active::after {
  color: #cf294b;
  border-top: 2px solid #cf294b;
  border-right: 2px solid #cf294b;
}

.doing {
  background-color: var(--light-color);
  padding-top: 125px;
  padding-bottom: 80px;
}
@media (max-width: 532px) {
  .doing {
    padding-top: 60px;
    padding-bottom: 40px;
  }
}
.doing__title {
  font-size: 44px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--dark-color);
  margin-bottom: 60px;
}
.doing__title .strong {
  color: var(--first-color);
}
@media (max-width: 1024px) {
  .doing__title {
    font-size: 36px;
  }
}
@media (max-width: 532px) {
  .doing__title {
    font-size: 28px;
  }
}
.doing__doing-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  row-gap: 60px;
  justify-content: space-between;
  margin-bottom: 60px;
}
.doing__sublist {
  list-style: inside;
  font-family: var(--second-family);
  font-size: 22px;
  font-weight: 400;
  color: var(--dark-color);
  margin-bottom: 0;
  padding-left: 30px;
  padding-right: 30px;
}
@media (max-width: 532px) {
  .doing__sublist {
    font-size: 16px;
  }
}
.doing__item {
  margin-bottom: 14px;
}
.doing__level {
  margin-top: 0;
  background-color: transparent;
}
.doing__level .doing__level-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--second-family);
  color: var(--dark-color);
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
  border-top: 3px solid #ccc;
  padding: 15px;
  cursor: pointer;
}
@media (max-width: 532px) {
  .doing__level .doing__level-title {
    font-size: 18px;
  }
}
.doing__level .doing__level-title path {
  stroke: #444;
  stroke-width: 0.8;
}
.doing__level .doing__level-title:focus {
  color: var(--first-color);
  outline: none;
}
.doing__level .doing__level-title:focus svg ellipse {
  fill: rgba(207, 41, 75, 0.6);
}
.doing__level .doing__level-title:focus svg path {
  stroke: #fff;
  fill: #fff;
}
@media (any-hover: hover) {
  .doing__level .doing__level-title:hover {
    color: var(--first-color);
  }
  .doing__level .doing__level-title:hover svg ellipse {
    fill: rgba(207, 41, 75, 0.6);
  }
  .doing__level .doing__level-title:hover svg path {
    stroke: #fff;
    fill: #fff;
  }
}
.doing__level .doing__level-title:active {
  color: var(--dark-color);
}
.doing__level .doing__level-title:active svg ellipse {
  fill: #CACACA;
}
.doing__level .doing__level-title:active svg path {
  fill: #000;
  stroke: #444;
}
.doing__decor {
  width: 100%;
  height: 1px;
  border-bottom: 3px solid #ccc;
}
.doing__level-icon {
  margin-left: 20px;
  display: block;
  flex-shrink: 0;
  transition: transform 0.5s;
}
@media (max-width: 532px) {
  .doing__level-icon {
    width: 32px;
  }
}
.doing__level-icon path {
  transition: fill 1.3s, stroke 0.3s;
}
.doing__level-icon ellipse {
  transition: fill 0.5s;
}

.ac .ac-trigger::after {
  content: none;
}

.is-active .doing__level-icon {
  transform: rotate(45deg);
}

.price {
  background-color: var(--light-color);
  padding-top: 20px;
  padding-bottom: 120px;
}
@media (max-width: 532px) {
  .price {
    padding-bottom: 60px;
  }
}
.price__title {
  font-size: 44px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--dark-color);
  margin-bottom: 60px;
}
@media (max-width: 1024px) {
  .price__title {
    font-size: 36px;
  }
}
@media (max-width: 532px) {
  .price__title {
    font-size: 28px;
    margin-bottom: 30px;
  }
}
.price__title .strong {
  color: var(--first-color);
  font-weight: 800;
}
.price__content {
  display: flex;
  justify-content: flex-start;
  color: var(--dark-color);
  margin-bottom: 0;
  border: 2px solid var(--dark-color);
  font-family: var(--second-family);
}
.price__content:not(:last-child) {
  border-bottom: none;
}
@media (max-width: 768px) {
  .price__content {
    flex-direction: column;
    padding-top: 20px;
  }
}
.price__type {
  display: flex;
  min-width: 300px;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
  border-right: 2px solid var(--dark-color);
}
@media (max-width: 768px) {
  .price__type {
    border-right: none;
    text-decoration: underline;
    text-underline-offset: 5px;
  }
}
@media (max-width: 532px) {
  .price__type {
    min-width: 0;
    font-size: 20px;
  }
}
.price__description {
  padding-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 768px) {
  .price__description {
    align-items: normal;
  }
}
.price__students {
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .price__students {
    font-size: 22px;
  }
}
@media (max-width: 532px) {
  .price__students {
    font-size: 18px;
  }
}
.price__list {
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 20px;
  line-height: 130%;
  font-weight: 400;
  margin-bottom: 30px;
}
.price__list:not(:last-child) {
  margin-bottom: 45px;
}
@media (max-width: 532px) {
  .price__list {
    font-size: 18px;
  }
}
.price__list-element {
  margin-bottom: 20px;
}

.consultation {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: var(--dark-color);
  border-top: 120px solid transparent;
  margin-top: -120px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  margin-bottom: 120px;
}
@media (max-width: 532px) {
  .consultation {
    margin-bottom: 60px;
  }
}
.consultation__container {
  display: flex;
  align-items: center;
}
@media (max-width: 532px) {
  .consultation__container {
    flex-direction: column;
  }
}
.consultation__title {
  display: block;
  margin-right: 25px;
  font-size: 30px;
  font-weight: 700;
  line-height: 130%;
  text-transform: uppercase;
  width: 100%;
}
@media (max-width: 1200px) {
  .consultation__title {
    font-size: 29px;
  }
}
@media (max-width: 532px) {
  .consultation__title {
    text-align: center;
    margin-bottom: 35px;
    font-size: 27px;
  }
}
.consultation__btn {
  display: block;
  background-color: var(--first-color);
  padding: 12px 25px;
  width: 280px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}

.comments {
  position: relative;
  background-color: var(--dark-color);
  padding-top: 80px;
  padding-bottom: 100px;
}
@media (max-width: 532px) {
  .comments {
    padding-bottom: 60px;
  }
}
.comments__swiper-slide {
  display: flex;
  justify-content: center;
  background-color: #000;
  padding-bottom: 75px;
}
.comments__screen {
  display: block;
  width: 100%;
  height: 480px;
}
@media (any-hover: hover) {
  .comments__screen:hover {
    filter: opacity(90%);
  }
}
@media (max-width: 1200px) {
  .comments__screen {
    height: initial;
  }
}
.comments__title {
  font-size: 44px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--light-color);
  margin-bottom: 70px;
}
@media (max-width: 1024px) {
  .comments__title {
    font-size: 36px;
  }
}
@media (max-width: 532px) {
  .comments__title {
    font-size: 28px;
  }
}
.comments__swiper {
  width: 100%;
  margin-bottom: 20px;
}
@media (max-width: 532px) {
  .comments__swiper {
    margin-bottom: 30px;
  }
}
.comments__text {
  font-family: var(--second-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  padding: 15px;
  padding-left: 160px;
  padding-right: 150px;
}
.comments__btn-next, .comments__btn-prev {
  position: absolute;
  z-index: 5;
  bottom: 16%;
  width: 44px;
  height: 44px;
  padding-top: 5px;
  border-radius: 50%;
  background-color: transparent;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
@media (max-width: 532px) {
  .comments__btn-next, .comments__btn-prev {
    width: 30px;
    height: 30px;
  }
}
.comments__btn-next svg, .comments__btn-prev svg {
  fill: #fff;
}
@media (any-hover: hover) {
  .comments__btn-next:hover, .comments__btn-prev:hover {
    background-color: var(--first-color);
  }
}
.comments__btn-next:active, .comments__btn-prev:active {
  background-color: #555;
}
.comments__btn-next:disabled, .comments__btn-prev:disabled {
  cursor: default;
  background-color: transparent;
}
.comments__btn-next:disabled svg, .comments__btn-prev:disabled svg {
  fill: rgba(255, 255, 255, 0.43);
}
.comments__btn-prev {
  left: 40px;
}
@media (max-width: 532px) {
  .comments__btn-prev {
    left: 25px;
  }
}
.comments__btn-next {
  right: 40px;
}
@media (max-width: 532px) {
  .comments__btn-next {
    right: 25px;
  }
}
.comments__btn-next svg {
  width: 23px;
  height: 14px;
}
@media (max-width: 532px) {
  .comments__btn-next svg {
    width: 12px;
    height: 11px;
  }
}
.comments__btn-prev svg {
  width: 23px;
  height: 14px;
}
@media (max-width: 532px) {
  .comments__btn-prev svg {
    width: 12px;
    height: 11px;
  }
}
.comments__more {
  display: flex;
  justify-content: center;
}
.comments__more-link {
  display: inline-block;
  font-family: var(--second-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  color: var(--light-color);
  text-align: center;
}
@media (max-width: 1024px) {
  .comments__more-link {
    font-size: 16px;
  }
}
@media (any-hover: hover) {
  .comments__more-link:hover {
    color: var(--first-color);
  }
}
.comments__more-link:active {
  color: var(--light-color);
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: var(--light-color);
  opacity: 0.5;
}

.swiper-pagination-bullet:not(:last-child) {
  margin-right: 8px !important;
}

.swiper-pagination-bullet-active {
  background-color: var(--first-color);
  opacity: 1;
}

.contacts {
  min-height: 900px;
  background-color: var(--dark-color);
  background-image: url(../img/contacts1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 160px;
  padding-bottom: 160px;
}
@media (max-width: 532px) {
  .contacts {
    min-height: 0;
    padding-top: 130px;
    padding-bottom: 120px;
  }
}
.contacts__question {
  display: block;
  width: 50%;
  font-family: var(--second-family);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .contacts__question {
    width: 100%;
  }
}
@media (max-width: 532px) {
  .contacts__question {
    font-size: 18px;
  }
}
.contacts__title {
  width: 60%;
  font-size: 52px;
  font-weight: 700;
  line-height: 123%;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 40px;
}
@media (max-width: 1024px) {
  .contacts__title {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .contacts__title {
    width: 100%;
  }
}
@media (max-width: 532px) {
  .contacts__title {
    font-size: 28px;
  }
}
.contacts__phone, .contacts__phone a {
  font-family: var(--second-family);
  font-size: 24px;
  font-weight: 400;
  line-height: 150%;
  color: #fff;
  text-align: center;
}
.contacts__mail, .contacts__mail a {
  font-family: var(--second-family);
  font-size: 24px;
  font-weight: 400;
  line-height: 150%;
  color: #fff;
  text-align: center;
  margin-bottom: 50px;
}
@media (any-hover: hover) {
  .contacts__phone a:hover {
    color: var(--first-color);
  }
}
.contacts__phone a:active {
  color: #fff;
}
@media (any-hover: hover) {
  .contacts__mail a:hover {
    color: var(--first-color);
  }
}
.contacts__mail a:active {
  color: #fff;
}
.contacts__social {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 0 auto;
}
.contacts__social-item {
  width: 60px;
  height: 60px;
}
.contacts__social-item svg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  fill: var(--first-color);
}
.contacts__social-item svg path {
  transition: fill 0.3s ease-in-out;
}
@media (any-hover: hover) {
  .contacts__social-item svg:hover {
    fill: var(--light-color);
  }
  .contacts__social-item svg:hover path:not(:first-child) {
    fill: var(--first-color);
  }
}
.contacts__social-item svg:active {
  fill: var(--first-color);
}
.contacts__social-item svg:active path:not(:first-child) {
  fill: var(--light-color);
}
@media (max-width: 532px) {
  .contacts__social-item svg {
    width: 48px;
    height: 48px;
  }
}