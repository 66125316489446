.price {
  background-color: var(--light-color);
  padding-top: 20px;
  padding-bottom: 120px;

  @include mobile {
    padding-bottom: 60px;
  }


  &__title {
    font-size: 44px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--dark-color);
    margin-bottom: 60px;

    @include tablet {
      font-size: 36px;
    }

    @include mobile {
      font-size: 28px;
      margin-bottom: 30px;
    }

    & .strong {
      color: var(--first-color);
      font-weight: 800;
    }
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    color: var(--dark-color);
    margin-bottom: 0;
    border: 2px solid var(--dark-color);
    font-family: var(--second-family);

    &:not(:last-child) {
      border-bottom: none;
    }

    @include minitablet {
      flex-direction: column;
      padding-top: 20px;
    }
  }

  &__type {
    display: flex;
    min-width: 300px;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 26px;
    font-weight: 600;
    text-transform: uppercase;
    border-right: 2px solid var(--dark-color);

    @include minitablet {
      border-right: none;
      text-decoration: underline;
      text-underline-offset: 5px;
    }

    @include mobile {
      min-width: 0;
      font-size: 20px;
    }

  }

  &__description {
    padding-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include minitablet {
      align-items: normal;
    }
  }

  &__students {
    display: block;
    // padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 26px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;

    @include minitablet {
      font-size: 22px;
    }

    @include mobile {
      font-size: 18px;
    }
  }

  &__list {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 20px;
    line-height: 130%;
    font-weight: 400;
    margin-bottom: 30px;

    &:not(:last-child) {
      margin-bottom: 45px;
    }

    @include mobile {
      font-size: 18px;
    }
  }

  &__list-element {
    margin-bottom: 20px;
  }

}