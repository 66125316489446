.send {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
  animation: anim 350ms ease-in-out;

  &__content {
    position: fixed;
    z-index: 200;
    width: 300px;
    height: 200px;
    background-color: white;
    top: calc(50% - 200px);
    left: calc(50% - 150px);
    border: 1px solid gray;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__text {
    font-size: 20px;
    font-family: var(--second-family);
    color: var(--dark-color);
    text-align: center;
    margin-bottom: 10px;

    &:nth-child(2) {
      margin-bottom: 38px;
    }
  }



  &__btn {
    display: block;
    font-size: 20px;
    font-family: var(--second-family);
    width: 150px;
    height: 36px;
    background-color: var(--first-color);
    color: white;
    margin: 0 auto;

    &:focus {
      outline: 2px solid var(--first-color);
      outline-offset: 4px;
    }
  }
}

.send--active {
  display: block;
}

.send--active::backdrop {
  background-color: red;
}

@keyframes anim {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}