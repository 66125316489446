.about-me {
  background-color: var(--dark-color);
  padding-top: 125px;
  padding-bottom: 100px;

  @include tablet {
    padding-top: 90px;
  }

  @include minitablet {
    padding-top: 10px;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include tablet {
      flex-direction: column-reverse;
      justify-content: flex-start;
    }
  }

  &__title {
    font-size: 46px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 30px;

    @include tablet {
      font-size: 36px;
    }

    @include mobile {
      font-size: 28px;
    }
  }

  &__photo {
    width: 49%;

    @include tablet {
      width: 100%;
    }
  }

  &__info {
    width: 47%;

    @include tablet {
      width: 100%;
    }
  }

  &__text {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 150%;
    font-family: var(--second-family);
    font-weight: 400;

    &:nth-last-child(3) {
      margin-bottom: 40px;
    }

    @include tablet {
      font-size: 15px;
    }
  }

  &__detail {
    display: inline-block;
    position: relative;
    color: var(--light-color);
    font-size: 18px;
    font-family: var(--second-family);
    font-weight: 400;

    @include tablet {
      font-size: 16px;
      margin-bottom: 50px;
    }

    &:not(:last-child) {
      margin-right: 30px;
    }

    &::after {
      position: absolute;
      right: -12px;
      top: 6px;
      content: '';
      width: 10px;
      height: 10px;
      border-top: 2px solid white;
      border-right: 2px solid white;
      transform: rotate(45deg);
      transition: color .3s ease-in-out, border .3s ease-in-out;
    }

    @include hover {
      color: #db833c;
    }

    @include hover {
      &::after {
        color: #db833c;
        border-top: 2px solid #db833c;
        border-right: 2px solid #db833c;
      }
    }

    &:active {
      color: #cf294b;
    }

    &:active::after {
      color: #cf294b;
      border-top: 2px solid #cf294b;
      border-right: 2px solid #cf294b;
    }
  }
}