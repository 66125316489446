.hero {
  position: relative;
  background-color: var(--dark-color);
  padding-top: 130px;
  padding-bottom: 86px;

  @include minitablet {
    padding-top: 180px;
  }

  @include mobile {
    padding-top: 280px;
    text-align: center;
  }


  &__bg {
    position: absolute;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('../img/gruppa.jpg');
    opacity: 0.25;
  }

  &__container {
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include minitablet {
      display: none;
    }
  }

  &__logo {
    display: flex;
    position: absolute;
    left: 0;
    width: 10%;
    height: 10%;
    z-index: 10;
    justify-content: center;
    align-items: center;
    opacity: 0;

    &--active {
      animation: ani 8s forwards 0.8s;
    }

    & img {
      width: 100%;
    }

    @include tablet {
      display: none;
    }
  }

  &__photo {
    width: 75%;

    @include minitablet {
      margin-bottom: 40px;
      width: 80%;
    }
  }

  &__title {
    width: 80%;
    font-weight: 700;
    font-size: 80px;
    line-height: 120%;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--light-color);
    margin-bottom: 40px;

    @include minidesk {
      font-size: 70px;
    }

    @include tablet {
      font-size: 36px;
    }

    @include minitablet {
      margin-bottom: 55px;
    }

    @include mobile {
      font-size: 28px;
    }

    & span {
      display: block;
      font-family: var(--second-family);
      font-size: 16px;
      line-height: 120%;
      font-weight: 300;
      margin-bottom: 30px;

      @include tablet {
        font-size: 15px;
      }
    }
  }

  &__text {
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    width: 70%;
    margin-bottom: 50px;

    @include tablet {
      width: 80%;
    }
  }

  &__sign {
    display: flex;
    gap: 3px;
    height: 62px;
    width: 70%;

    @include tablet {
      height: 45px;
    }
  }

  &__sign-icon {
    width: 62px;
    height: 100%;
    background-color: #cf294b;
    background-image: url('../img/icon1.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 40px;

    @include mobile {
      display: none;
    }
  }

  &__sign-btn {
    position: relative;
    z-index: 7;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: #cf294b;
    color: var(--light-color);
    padding: 10px 20px;
    cursor: pointer;

    @include tablet {
      font-size: 16px;
      padding: 8px 10px;
    }


  }

  &__container--mobile {
    display: none;

    @include minitablet {
      display: flex;
      flex-direction: column;
    }
  }
}

@keyframes ani {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
    width: 100%;
    height: 100%;
  }

  50% {
    opacity: 1;
    width: 100%;
    height: 100%;
  }

  100% {
    opacity: 0;
    width: 10%;
    height: 10%;
  }
}
