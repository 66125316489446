.doing {
  background-color: var(--light-color);
  padding-top: 125px;
  padding-bottom: 80px;

  @include mobile {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  &__title {
    font-size: 44px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--dark-color);
    margin-bottom: 60px;

    & .strong {
      color: var(--first-color);
    }

    @include tablet {
      font-size: 36px;
    }

    @include mobile {
      font-size: 28px;
    }
  }

  &__doing-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    row-gap: 60px;
    justify-content: space-between;
    margin-bottom: 60px;
  }

  &__sublist {
    list-style: inside;
    font-family: var(--second-family);
    font-size: 22px;
    font-weight: 400;
    color: var(--dark-color);
    margin-bottom: 0;
    padding-left: 30px;
    padding-right: 30px;

    @include mobile {
      font-size: 16px;
    }
  }

  &__item {
    margin-bottom: 14px;
  }

  &__level {
    margin-top: 0;
    background-color: transparent;
  }

  &__level &__level-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--second-family);
    color: var(--dark-color);
    font-size: 26px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;
    border-top: 3px solid #ccc;
    padding: 15px;
    cursor: pointer;

    @include mobile {
      font-size: 18px;
    }

    & path {
      stroke: #444;
      stroke-width: 0.8;
    }

    &:focus {
      color: var(--first-color);
      outline: none;

      & svg ellipse {
        fill: rgba(207, 41, 75, 0.6);
      }

      & svg path {
        stroke: #fff;
        fill: #fff;
      }
    }

    @include hover {
      color: var(--first-color);

      & svg ellipse {
        fill: rgba(207, 41, 75, 0.6);
      }

      & svg path {
        stroke: #fff;
        fill: #fff;
      }
    }

    &:active {
      color: var(--dark-color);

      & svg ellipse {
        fill: #CACACA;
      }

      & svg path {
        fill: #000;
        stroke: #444;
      }
    }
  }

  &__decor {
    width: 100%;
    height: 1px;
    border-bottom: 3px solid #ccc;

  }

  &__level-icon {
    margin-left: 20px;
    display: block;
    flex-shrink: 0;
    transition: transform .5s;

    @include mobile {
      width: 32px;
    }

    & path {
      transition: fill 1.3s, stroke 0.3s;
    }

    & ellipse {
      transition: fill 0.5s;
    }
  }
}

.ac .ac-trigger::after {
  content: none;
}

.is-active .doing__level-icon {
  transform: rotate(45deg);
}